import React from 'react';
import ReactDOM from 'react-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

// Bootstrap (we only need the SCSS, there is no need for any Javascript
// with react-bootstrap).
import "bootstrap-scss/bootstrap.scss";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const instance = process.env.REACT_APP_MATOMO_URL ? createInstance({
  urlBase: process.env.REACT_APP_MATOMO_URL,
  siteId: parseInt(process.env.REACT_APP_MATOMO_SITEID),
  disabled: process.env.REACT_APP_MATOMO_ENABLED !== 'true',
  heartBeat: {
    active: true,
    // seconds: 15,
  },
  // Link tracking will be handled specially in the MatomoTracker component.
  // https://github.com/Amsterdam/matomo-tracker/tree/master/packages/react#spa-link-tracking
  linkTracking: false,
  trackPageView: true,
  configurations: {
    // Disable cookies by default.
    disableCookies: true,
  },
}) : undefined;

ReactDOM.render(
  <MatomoProvider value={instance}>
    <App />
  </MatomoProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
