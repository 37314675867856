import React, { Component } from 'react';

import Pagination from 'react-bootstrap/Pagination';

import PropTypes from 'prop-types';
import Permissions from '../../access/Permissions';

class Paginate extends Component {

  constructor(props) {
    super(props);
    this.query = new URLSearchParams(props.location.search);
    this.urlPath = props.location.pathname;
    this.auth = false;
    this.state = {
      page: this.query.get('page') || 1,
    };

    this.fetchPaginatedResults = this.fetchPaginatedResults.bind(this);
    this.generateProps = this.generateProps.bind(this);
    this.onClickPagination = this.onClickPagination.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location !== prevProps.location) {
      this.query = new URLSearchParams(this.props.location.search);
      this.urlPath = this.props.location.pathname;
    }
  }

  onClickPagination(url, page, e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (page === this.state.page) {
      // Nothing to do here.
      return;
    }

    this.setState({
      page: page,
    }, this.fetchPaginatedResults.bind(this, page, url));
  }

  fetchPaginatedResults(page, url) {
    if (url) {
      this.props.history.push(url);
    }
    this.props.fetchData(page);
  }

  generateProps(page) {
    this.query.set('page', page);
    const url = this.urlPath + '?' + this.query.toString();

    return {
      href: url,
      onClick: this.onClickPagination.bind(this, url, page),
    };
  }

  render() {
    if (this.props.pagination) {
      const paginationItems = [];
      const {totalPages, page, prevPage, nextPage} = this.props.pagination;
      if (prevPage || nextPage) {

        const maxPage = Math.min(totalPages, (page + 4));
        for (let number = 1; number <= maxPage; number++) {
          const isMax = number === maxPage;
          if (isMax && (number - page) > 3) {
            paginationItems.push(<Pagination.Ellipsis key="next-ellipsis" disabled/>);
          }
          if (number === 1 || isMax || Math.abs(page - number) < 3) {
            const currentPage = isMax ? totalPages : number;
            paginationItems.push(
              <Pagination.Item key={currentPage}
                               active={currentPage === page}
                               {...this.generateProps(currentPage)}>
                {currentPage}
              </Pagination.Item>
            );

            if (number === 1 && (page - number) > 3) {
              paginationItems.push(<Pagination.Ellipsis key="prev-ellipsis" disabled/>);
            }
          }
        }

        return (
          <Pagination>
            {(page - 4 > 0) && <Pagination.First {...this.generateProps(1)} />}
            {prevPage && <Pagination.Prev {...this.generateProps(page - 1)}/>}
            {paginationItems}
            {nextPage && <Pagination.Next {...this.generateProps(page + 1)}/>}
            {(totalPages - page > 4) && <Pagination.Last {...this.generateProps(totalPages)}/>}
          </Pagination>
        );
      }
    }

    return null;
  }

}

export function getPaginationData(results) {
  return {
    totalDocs: results.totalDocs,
    limit: results.limit,
    totalPages: results.totalPages,
    page: results.page,
    prevPage: results.prevPage,
    nextPage: results.nextPage,
    hasPrevPage: results.hasPrevPage,
    hasNextPage: results.hasNextPage,
  };
}

Paginate.propTypes = {
  // Fetch paginated data.
  fetchData: PropTypes.func.isRequired,
  permissions: PropTypes.instanceOf(Permissions),
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  pagination: PropTypes.shape({
    totalDocs: PropTypes.number,
    limit: PropTypes.number,
    totalPages: PropTypes.number,
    page: PropTypes.number,
    prevPage: PropTypes.number,
    nextPage: PropTypes.number,
    hasPrevPage: PropTypes.bool,
    hasNextPage: PropTypes.bool,
  }),
};

export default Paginate;
