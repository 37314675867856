import React, { Component } from 'react';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';

import { LinkContainer } from 'react-router-bootstrap';

import Login from "./access/Login";
import Permissions from "./access/Permissions";
import ServerAPI from "./ServerAPI";
import PropTypes from 'prop-types';

class Header extends Component {

  constructor(props) {
    super(props);
    this.localLogin = this.localLogin.bind(this);
    this.logout = this.logout.bind(this);
  }

  async localLogin(username, password) {
    await this.props.localLogin(username, password);
  }

  async logout() {
    await this.props.logout();
  }

  render() {
    let anyPermission = false;
    if (this.props.permissions)
      anyPermission = this.props.permissions.anyPermission()
    return (
      <>
        {/* jumbotron for anonymous users */}
        {(!anyPermission || !this.props.permissions.loggedIn()) &&
        <>
            <div className="jumbotron mb-5 d-flex align-items-center flex-column justify-content-center bg-dark text-white" id="header"
                 style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 100%), url(/img/banner.jpg)', backgroundSize: "cover" }}>
              <h1 className="display-3">Public<b>a11y</b></h1>
              <p className="lead">Free accessibility scans for public sector websites.</p>
              <p>
                <Login variant="light" size="lg" server={this.props.server} permissions={this.props.permissions}
                       localLogin={this.localLogin} logout={this.logout} />{' '}
                <Button href="mailto:info@zodiacmedia.co.uk?subject=Publica11y.org audit request"
                 variant="outline-light" size="lg">Contact us</Button>
              </p>
            </div>
          </>
        }
        {/* navigation for logged in users */}
        {(anyPermission && this.props.permissions.loggedIn()) &&
          <>
            <header>
              <Navbar bg="dark" variant="dark" className="mb-5">
                <Navbar.Brand href="/">Publica11y</Navbar.Brand>
                <Navbar.Toggle aria-controls="header-nav" />
                <Navbar.Collapse id="header-nav">
                  <Nav className="mr-auto">
                    <NavDropdown title="Audits" id="header-audits-nav">
                      <LinkContainer to="/audits/" exact>
                        <NavDropdown.Item>View all audits</NavDropdown.Item>
                      </LinkContainer>
                      {this.props.permissions.anyAuditCreateAllowed() &&
                      <>
                        <LinkContainer to="/audits/create" exact>
                          <NavDropdown.Item>Start a new audit</NavDropdown.Item>
                        </LinkContainer>
                      </>
                      }
                    </NavDropdown>
                    {this.props.permissions.userAndGroupEditAllowed() &&
                    <>
                      <Nav className="mr-auto">
                        <NavDropdown title="Users" id="header-users-nav">
                          <LinkContainer to="/users/" exact>
                            <NavDropdown.Item>View all users</NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/users/create" exact>
                            <NavDropdown.Item>Create a new user</NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      </Nav>
                      <Nav className="mr-auto">
                        <NavDropdown title="Groups" id="header-groups-nav">
                          <LinkContainer to="/groups/" exact>
                            <NavDropdown.Item>View all groups</NavDropdown.Item>
                          </LinkContainer>
                          <LinkContainer to="/groups/create" exact>
                            <NavDropdown.Item>Create a new group</NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      </Nav>
                    </>
                    }
                  </Nav>
                </Navbar.Collapse>

                <Nav className="mr-auto">
                  <Nav.Link href="#home">
                    <Login variant="dark" size="sm" server={this.props.server} permissions={this.props.permissions}
                           localLogin={this.localLogin} logout={this.logout} />
                  </Nav.Link>
                </Nav>
              </Navbar>
            </header>
          </>
        }
      </>
    );
  }
}

Header.propTypes = {
  permissions: PropTypes.instanceOf(Permissions),
  localLogin: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  server: PropTypes.instanceOf(ServerAPI).isRequired,
};

export default Header;
