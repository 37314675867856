import React from 'react';

import Accordion from 'react-bootstrap/Accordion';
import Alert from 'react-bootstrap/Alert';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';

import { LinkContainer } from 'react-router-bootstrap';

import PropTypes from 'prop-types';

import ServerAPI from '../ServerAPI';
import Permissions from '../access/Permissions';
import './AuditForm.css';


class AuditForm extends React.Component {

  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    this.state = {
      error: null,
      label: query.get('label') || '',
      firstURL: query.get('firstURL') || '',
      standard: query.get('wcag21aa') || 'wcag21aa',
      sitemaps: (query.get('sitemaps') !== null) ? (query.get('sitemaps') === 'true') : false,
      sitemapName: query.get('sitemapName') || 'sitemap.xml',
      customList: (query.get('customList') !== null) ? (query.get('customList') === 'true') : false,
      urlList: query.get('urlList') || '',
      checkSubdomains: (query.get('checkSubdomains') !== null) ? (query.get('checkSubdomains') === 'true') : true,
      maxDepth: query.get('maxDepth') !== null ? parseInt(query.get('maxDepth')) : 1,
      maxPagesPerDomain: parseInt(query.get('maxPagesPerDomain')) || 0,
      includeMatch: query.get('includeMatch') || '',
      excludeURLs: query.get('excludeURLs') || '',
      browser: query.get('browser') || 'firefox',
      postLoadingDelay: query.get('postLoadingDelay') !== null ? parseInt(query.get('postLoadingDelay')) : 2000,
      auditId: null,
      // Advanced states
      tabKey: props.location.hash.substr(1) || 'general',
      authMethod: query.get('authMethod') || '', // form, cookies
      loginURL: query.get('loginURL') || '',
      loginUser: query.get('loginUser') || '',
      loginPass: query.get('loginPass') || '',
      sessionCookies: query.get('sessionCookies') || '',
      statusCheckMode: query.get('statusCheckMode') || '',
      extraOptions: query.get('extraOptions') || '',
    };
    this.checkDelay = 1000;
    this.checkInterval = null;

    this.handleChange = this.handleChange.bind(this);
    this.updateTab = this.updateTab.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  componentDidMount() {
    document.title = "New Accessibility Audit";
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.error && !prevState.error)
      document.querySelector('.alert').focus();
  }

  updateTab(tabKey) {
    this.setState({
      tabKey,
    });
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: this.props.location.search,
      // Only set the hash for non-default tabs.
      hash: (tabKey === 'general' ? '' : tabKey),
    });
  }

  handleChange(event) {
    const target = event.target;
    // Default to 0 if the number is invalid.
    const value = target.type === 'checkbox' ? target.checked :
      (target.type === 'number' ? (parseInt(target.value) || 0) : target.value);
    const name = target.name;
    const state = {
      [name]: value
    };
    if (name === 'authMethod' && value) {
      // Switch to chromium by default, since the form login behaviour is somewhat inconsistent on repeated runs
      // on Firefox.
      // @see https://forms.towerhamlets.gov.uk/login/
      state['browser'] = 'chrome';
    }
    if (name === 'customList' && value) {
      if (!this.state.maxDepth || this.state.maxDepth === 1) {
        // Switch to 0, since that's most likely what you want.
        state['maxDepth'] = 0;
      }
    }
    this.setState(state);
  }

  async startAudit() {
    this.setState({ error: null });
    try {
      const params = {
        firstURL: this.state.firstURL,
        label: this.state.label,
        standard: this.state.standard,
        customList: this.state.customList,
        // Store empty string when the customList option is unchecked.
        urlList: this.state.customList ? this.state.urlList : '',
        checkSubdomains: this.state.checkSubdomains,
        maxDepth: this.state.maxDepth,
        maxPagesPerDomain: this.state.maxPagesPerDomain,
        sitemaps: this.state.sitemaps,
        sitemapName: this.state.sitemapName || 'sitemap.xml',
        includeMatch: this.state.includeMatch.trim(),
        excludeURLs: this.state.excludeURLs.trim(),
        browser: this.state.browser,
        postLoadingDelay: this.state.postLoadingDelay,
        authMethod: this.state.authMethod,
        statusCheckMode: this.state.statusCheckMode,
        extraOptions: this.state.extraOptions,
      };

      if (this.state.authMethod === 'form') {
        params.loginURL = this.state.loginURL;
        params.loginUser = this.state.loginUser;
        params.loginPass = this.state.loginPass;
      }
      else if (this.state.authMethod === 'cookies') {
        params.sessionCookies = this.state.sessionCookies;
      }

      const audit = await this.props.server.startAudit(params);
      this.props.history.push('/audits/' + audit.id + '/status');
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  formSubmit(e) {
    e.preventDefault();
    this.startAudit();
  }

  closeAlert() {
    this.setState({ error: null });
  }

  renderBasicConfigurations() {
    return (
      <>
        <Form.Group as={Row} controlId="label">
          <Form.Label column sm="5">Audit name</Form.Label>
          <Col sm="7">
            <Form.Control name="label" value={this.state.label}
                            placeholder="Enter your audit name (optional)"
                            onChange={this.handleChange}/>
            <Form.Text className="text-muted">
                The human-friendly name for the audit.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="firstURL" className="required">
          <Form.Label column sm="5">Initial URL</Form.Label>
          <Col sm="7">
            <Form.Control name="firstURL" type="url" value={this.state.firstURL} required
                          onChange={this.handleChange}/>
            <Form.Text className="text-muted">
                The initial page to audit. This should <em>not</em> be a sitemap URL.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="standard">
          <Form.Label column sm="5">Standard</Form.Label>
          <Col sm="7">
            <Form.Control name="standard" as="select" value={this.state.standard}
                            onChange={this.handleChange}>
              <option value="wcag2a">WCAG 2.0 Level A</option>
              <option value="wcag2aa">WCAG 2.0 Level AA</option>
              <option value="wcag21aa">WCAG 2.1 Level AA</option>
              <option value="section508">Section 508</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="sitemaps">
          <Col sm="5"/>
          <Col sm="7">
            <Form.Check name="sitemaps" type="checkbox" value={this.state.sitemaps}
                          onChange={this.handleChange} checked={this.state.sitemaps}
                          disabled={this.state.customList}
                          label="Use site maps to discover pages"/>
          </Col>
        </Form.Group>
        {
          this.state.sitemaps &&
            (
              <Form.Group as={Row} controlId="sitemapName" className="required">
                <Form.Label column sm="5">Sitemap name</Form.Label>
                <Col sm="7">
                  <Form.Control name="sitemapName" value={this.state.sitemapName}
                                  required={true}
                                  onChange={this.handleChange}
                                  placeholder="sitemap.xml" />
                  <Form.Text className="text-muted">
                      The path to the sitemap to index relative to the initial URL (without the <code>/</code> prefix).
                  </Form.Text>
                </Col>
              </Form.Group>
            )
        }
        <Form.Group as={Row} controlId="customList">
          <Col sm="5"/>
          <Col sm="7">
            <Form.Check name="customList" type="checkbox" value={this.state.customList}
                          onChange={this.handleChange} checked={this.state.customList}
                          disabled={this.state.sitemaps}
                          label="Use custom URL list"
                          title="Use a custom URL list instead of a sitemap."
            />
          </Col>
        </Form.Group>
        {
          this.state.customList &&
            (
              <Form.Group as={Row} controlId="urlList">
                <Form.Label column sm="5">URL List</Form.Label>
                <Col sm="7">
                  <Form.Control name="urlList" as="textarea" value={this.state.urlList}
                                  onChange={this.handleChange}
                                  rows="15"
                                  placeholder="List of URLs to scan" />
                  <Form.Text className="text-muted">
                      URLs may be absolute or path relative (starting with a <code>/</code>) to the initial URL.
                  </Form.Text>
                </Col>
              </Form.Group>
            )
        }
        <Form.Group as={Row} controlId="checkSubdomains">
          <Col sm="5"/>
          <Col sm="7">
            <Form.Check name="checkSubdomains" type="checkbox" value={this.state.checkSubdomains}
                          onChange={this.handleChange} checked={this.state.checkSubdomains}
                          disabled={this.state.customList}
                          label="Check subdomains"/>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="maxDepth">
          <Form.Label column sm="5">Maximum crawling depth</Form.Label>
          <Col sm="7">
            <Form.Control name="maxDepth" type="number" value={this.state.maxDepth}
                            min={0}
                            onChange={this.handleChange}/>
            <Form.Text className="text-muted">
                How many levels deep should <code>&lt;a/&gt;</code> tags be crawled.<br/>
                Specify <b>0</b> if no links should be crawled, recommended when using a custom URL list.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="maxPagesPerDomain">
          <Form.Label column sm="5">Maximum number of pages checked per domain</Form.Label>
          <Col sm="7">
            <Form.Control name="maxPagesPerDomain" type="number" value={this.state.maxPagesPerDomain}
                            min={0}
                            onChange={this.handleChange}/>
            <Form.Text className="text-muted">
                Specify <b>0</b> for no limit.
            </Form.Text>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="browser">
          <Form.Label column sm="5">Browser</Form.Label>
          <Col sm="7">
            <Form.Control name="browser" as="select" value={this.state.browser}
                            onChange={this.handleChange}>
              <option value="firefox">Firefox</option>
              <option value="chrome">Chromium</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="postLoadingDelay">
          <Form.Label column sm="5">Additional delay to let dynamic pages load (ms)</Form.Label>
          <Col sm="7">
            <InputGroup>
              <Form.Control name="postLoadingDelay" type="number" value={this.state.postLoadingDelay}
                              min={0}
                              onChange={this.handleChange}/>
              <InputGroup.Append>
                <InputGroup.Text>ms</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Form.Text className="text-muted">
                Number of milliseconds to wait for each page before attempting to crawl
                the <code>&lt;a/&gt;</code> tags.<br/>
                This is especially useful if the pages are Javascript based.
            </Form.Text>
          </Col>
        </Form.Group>
      </>
    );
  }

  renderAdvancedConfigurations() {
    // Expand the accordion by default when the value is set.
    const expandAccordion = (this.state.statusCheckMode || this.state.extraOptions) ? '0' : undefined;

    return (
      <>
        <Card>
          {/* https://support.siteimprove.com/hc/en-gb/articles/115000083211-Can-Siteimprove-crawl-an-intranet-and-other-non-public-sites- */}
          <Card.Header>Authentication</Card.Header>
          <Card.Body>
            <Form.Group as={Row} controlId="authMethod">
              <Form.Label column sm="5">Authentication method</Form.Label>
              <Col sm="7">
                <Form.Control name="authMethod" as="select" value={this.state.authMethod}
                              onChange={this.handleChange}>
                  <option value="">None</option>
                  <option value="form">Form login</option>
                  <option value="cookies">Session Cookies</option>
                </Form.Control>
                <Form.Text className="text-muted">
                  The authentication method. You most likely want to use the Chromium browser with this for the best
                  results.
                </Form.Text>
              </Col>
            </Form.Group>
            {this.renderAuthMethodForm()}
          </Card.Body>
        </Card>

        <Card className="mt-3">
          <Card.Header>Path</Card.Header>
          <Card.Body>
            <Form.Group as={Row} controlId="includeMatch">
              <Form.Label column sm="5">Include only paths matching the regular
                expression</Form.Label>
              <Col sm="7">
                <InputGroup className="include-match">
                  <InputGroup.Prepend>
                    <InputGroup.Text>/</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control name="includeMatch" value={this.state.includeMatch}
                                onChange={this.handleChange}/>
                  <InputGroup.Append>
                    <InputGroup.Text>/</InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="excludeURLs">
              <Form.Label column sm="5">Exclude URL paths</Form.Label>
              <Col sm="7">
                <Form.Control name="excludeURLs" as="textarea" value={this.state.excludeURLs}
                              onChange={this.handleChange}
                              rows="10"
                              placeholder={[
                                '/user/logout',
                                '/wp-login.php?action=logout',
                                '/customer/account/logout',
                                '/authapi/logout',
                                '/logout',
                                'logout',
                                '?logout',
                                'auth',
                              ].join('\n')} />
                <Form.Text className="text-muted">
                  A URL is excluded if it contains anything on the list.
                  e.g. <code>/user/logout</code> to exclude Drupal logout URLs.<br/>
                  It may also include the domain name e.g. <code>example.com/logout</code>, but is rarely required/recommended.
                </Form.Text>
              </Col>
            </Form.Group>
          </Card.Body>
        </Card>

        <Accordion className="mt-3" defaultActiveKey={expandAccordion}>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Advanced Preferences
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form.Group as={Row} controlId="statusCheckMode">
                  <Form.Label column sm="5">Status check method</Form.Label>
                  <Col sm="7">
                    <Form.Control name="statusCheckMode" as="select" value={this.state.statusCheckMode}
                                  onChange={this.handleChange}>
                      <option value="">HEAD</option>
                      <option value="get">GET</option>
                      <option value="none">None</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                      The method to use to determine whether a URL can be audited.<br/>
                      This is needed to ensure that only HTML pages are audited, as some servers might not
                      support <code>HEAD</code> requests.<br/>
                      Using the <b>None</b> mode will cause the audit to not store the HTTP status code results.
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="extraOptions">
                  <Form.Label column sm="5">Configuration options</Form.Label>
                  <Col sm="7">
                    <Form.Control name="extraOptions" as="textarea" value={this.state.extraOptions}
                                  className="code-area" spellCheck="false" onChange={this.handleChange} rows="10"
                                  placeholder={[
                                    '# Specify a custom user agent.',
                                    'UA: publica11y-useragent',
                                    '# Run with a smaller device resolution.',
                                    'MOBILE: true',
                                    '',
                                    '# Enable a specific feature flag for the audit tool to act on.',
                                    'env.FEATURE_FLAG: value',
                                  ].join('\n')}/>
                    <Form.Text className="text-muted">
                      These are special configuration flags to pass to the audit tool.
                      Only set this if you know what you're doing.<br/>
                      It's typically in the format: <b><code>FLAG_NAME: FLAG_VALUE</code></b>.<br/>
                      Specific internal audit feature flags may be set by specifying a <code>env.</code> prefix.
                      e.g. <code>env.LOG_COOKIES: true</code> to log how the cookies are handled during the audit process.
                    </Form.Text>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    );
  }

  renderAuthMethodForm() {
    switch (this.state.authMethod) {
    case 'form':
      return (
        <>
          <Form.Group as={Row} controlId="loginURL" className="required">
            <Form.Label column sm="5">Login URL</Form.Label>
            <Col sm="7">
              <Form.Control name="loginURL" type="url" value={this.state.loginURL}
                            placeholder="https://example.com/user/login"
                            required
                            onChange={this.handleChange}/>
              <Form.Text className="text-muted">
                The URL to use to log onto the site.
                You most likely want to add the logout URL to the exclude path list to avoid accidentally logging out
                during the audit scan.
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="loginUser" className="required">
            <Form.Label column sm="5">Username</Form.Label>
            <Col sm="7">
              <Form.Control name="loginUser" value={this.state.loginUser}
                            required
                            onChange={this.handleChange}
                            autoComplete="off"
                            placeholder="Enter your username" />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="loginPass" className="required">
            <Form.Label column sm="5">Password</Form.Label>
            <Col sm="7">
              <Form.Control name="loginPass" type="password" value={this.state.loginPass}
                            required
                            onChange={this.handleChange}
                            autoComplete="off"
                            placeholder="Enter your password" />
            </Col>
          </Form.Group>
        </>
      );
    case 'cookies':
      return (
        <>
          <Form.Group as={Row} controlId="sessionCookies" className="required">
            <Form.Label column sm="5">Session cookies</Form.Label>
            <Col sm="7">
              <Form.Control name="sessionCookies" as="textarea" value={this.state.sessionCookies} required
                            className="code-area" spellCheck="false" onChange={this.handleChange} rows="10" />
              <Form.Text className="text-muted">
                Session cookies in <a target="_blank" rel="noopener noreferrer" href="https://docs.funnelback.com/collections/collection-types/web/web-crawler-settings/cookies_txt.html#format">Netscape cookie format</a>.
                You will need to login first (as a non-admin user), then copy your session cookies into the tool.<br/>
                This will need to be exported using a browser extension like <a target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/editthiscookie/fngmhnnpilhplaeedifhccceomclgfbg/related?hl=en">EditThisCookie</a>.<br/>
                If using EditThisCookie, you will have to <a target="_blank" rel="noopener noreferrer" href="chrome-extension://fngmhnnpilhplaeedifhccceomclgfbg/options_pages/user_preferences.html">update</a> the default settings before exporting.<br/>
                <b>IMPORTANT:</b> Please ensure that the session cookie is recent otherwise you might receive outdated scan results.
              </Form.Text>
            </Col>
          </Form.Group>
        </>
      );
    default:
      return null;
    }
  }

  render() {
    if (!this.props.permissions || !this.props.permissions.anyAuditCreateAllowed())
      return <p>You are not allowed to create new audits.</p>;
    return (
      <>
        <h1>Start A New Audit</h1>
        <Breadcrumb>
          <LinkContainer to="/audits/">
            <Breadcrumb.Item>Audits</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Start A New Audit</Breadcrumb.Item>
        </Breadcrumb>
        <Alert show={this.state.error != null} variant="danger" dismissible
            onClose={this.closeAlert} tabIndex="0">
          {this.state.error}
        </Alert>
        <Form onSubmit={this.formSubmit} className="form mt-3">
          <div className={'audit-tabs'}>
            <Tabs
                id="audit-tabs"
                activeKey={this.state.tabKey}
                onSelect={this.updateTab}
            >
              <Tab eventKey="general" title="General">
                {this.renderBasicConfigurations()}
              </Tab>
              <Tab eventKey="advanced" title="Advanced">
                {this.renderAdvancedConfigurations()}
              </Tab>
            </Tabs>
          </div>
          <div className="text-center">
            <Button variant="primary" type="submit">
              Start Audit
            </Button>
          </div>
        </Form>
      </>
    );
  }

}

AuditForm.propTypes = {
  permissions: PropTypes.instanceOf(Permissions),
  server: PropTypes.instanceOf(ServerAPI).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
};

export default AuditForm;
