import React from 'react';

import { faCircle, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <footer className="mt-auto bg-dark text-white py-4">
      <div className="container text-center">
        <div className="row align-items-center">
          <div className="col-lg-4 text-lg-left">
            &copy; 2023 Zodiac Media Ltd
          </div>
          <div className="col-lg-4 my-3 my-lg-0">
            <a href="mailto:info@zodiacmedia.co.uk" title="Email">
              <span className="fa-stack">
                <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                <FontAwesomeIcon icon={faEnvelope} className="fa-stack-1x" />
              </span>
            </a>
            <a href="https://twitter.com/zodiac_media_uk" title="Twitter" target="_blank"
               rel="noopener noreferrer">
              <span className="fa-stack">
                <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                <FontAwesomeIcon icon={faTwitter} className="fa-stack-1x" />
              </span>
            </a>
            <a href="https://www.linkedin.com/company/zodiac-media" title="Linkedin" target="_blank"
               rel="noopener noreferrer">
              <span className="fa-stack">
                <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                <FontAwesomeIcon icon={faLinkedinIn} className="fa-stack-1x" />
              </span>
            </a>
          </div>
          <div className="col-lg-4 text-lg-right">
            <a className="mr-3" href="https://www.zodiacmedia.co.uk/services">Development services</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
