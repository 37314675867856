import React, { Component } from 'react';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import { faCircle, faUniversalAccess, faClipboardList, faSyncAlt, faTrashAlt, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import ImportButton from './ImportButton';
import Permissions from '../access/Permissions';
import ServerAPI from '../ServerAPI';
import Paginate, { getPaginationData } from './components/Paginate';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';


class AuditList extends Component {

  constructor(props) {
    super(props);
    this.auth = false;
    this.state = {
      audits: null,
      pagination: null,
      username: null,
      password: null,
      error: null,
      auditfilter: '',
    };
    this.getAudits = this.getAudits.bind(this);
    this.handleFilterMode = this.handleFilterMode.bind(this);
  }

  async componentDidMount() {
    const authenticated = this.props.permissions.user._id !== 'guest';
    if (!this.auth && authenticated) {
      await this.getAudits();
    }
    this.auth = authenticated;
    document.title = "Publica11y.org - Free Accessibility Scans for the Public Sector";
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.error && !prevState.error) {
      document.querySelector('.alert').focus();
    }
    const authenticated = this.props.permissions.user._id !== 'guest';
    if (!this.auth && authenticated) {
      await this.getAudits();
    }
    this.auth = authenticated;
  }

  handleFilterMode(event) {
    const target = event.target;
    // Default to 0 if the number is invalid.
    const value = target.type === 'checkbox' ? target.checked :
      (target.type === 'number' ? (parseInt(target.value) || 0) : target.value);
    this.setState({
      auditfilter: value,
    }, () => {
      // Get the audits.
     this.getAudits();
    });
  }

  async getAudits(page) {
    try {
      if (!page) {
        const query = new URLSearchParams(this.props.location.search);
        page = query.get('page') || 1;
      }
      const results = await this.props.server.getAudits(page, this.state.auditfilter);
      this.setState({
        audits: results.docs,
        pagination: getPaginationData(results),
      });
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  removeAudit(auditId) {
    const query = new URLSearchParams(this.props.location.search);
    const page = query.get('page') || 1;
    this.props.server.removeAudit(auditId)
      .then(() => this.props.server.getAudits(page, this.state.auditfilter))
      .then((results) => {
        this.setState({
          audits: results.docs,
          pagination: getPaginationData(results),
        });
      })
      .catch((error) => {
        this.setState({ error: "Remove audit: " + error });
      });
  }

  exportAudit(auditId) {
    this.props.server.exportAudit(auditId);
  }

  render() {
    let anyPermission = false;
    if (this.props.permissions)
      anyPermission = this.props.permissions.anyPermission();
    let auditsHTML = null;
    if (anyPermission && this.state.audits != null) {
      const sortedAudits = [...this.state.audits]
        .sort((a,b) => b.dateStarted - a.dateStarted);
      auditsHTML = sortedAudits.map(audit => (
        this.props.permissions.domainReadAllowed(audit.initialDomainName) &&
        <tr key={audit._id}>
          <td className="code"><Link to={'/audits/'+audit._id}>{audit.initialDomainName}</Link></td>
          <td className="code">{audit.label}</td>
          <td className="text-right">{(new Date(audit.dateStarted)).toLocaleDateString()}</td>
          <td className="text-right">{audit.nbCheckedURLs}</td>
          <td className="text-right">{audit.nbViolations}</td>
          {this.props.permissions.domainDeleteAllowed(audit.initialDomainName) &&
            <td className="text-right">
              <Button title="Remove" variant="danger" size="xs" onClick={(e) => this.removeAudit(audit._id)}><FontAwesomeIcon icon={faTrashAlt} title="Remove" /></Button>
              { ' ' }
              {this.props.permissions.anyAuditCreateAllowed() &&
                <Button title="Export Results" variant="info" size="xs" onClick={(e) => this.exportAudit(audit._id)}><FontAwesomeIcon icon={faDownload} title="Export Results" /></Button>
              }
            </td>
          }
        </tr>
      ));
    }

    return (
      <>
        <Alert show={this.state.error != null} variant="danger" dismissible
            onClose={() => this.setState({ error: null })} tabIndex="0">
          {this.state.error}
        </Alert>
        {(!anyPermission || !this.props.permissions.loggedIn()) &&
            <>
              <section id="home-services">
                <div className="text-center mb-5 pb-3">
                  <h3 className="text-muted">We make accessibility compliance easy to understand.</h3>
                </div>
                <div className="row text-center">
                  <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                      <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                      <FontAwesomeIcon icon={faUniversalAccess} className="fa-stack-1x" />
                    </span>
                    <h4 className="my-3">Standards compliance</h4>
                    <p className="text-muted">We support auditing against the WCAG 2.0 Level A or AA, WCAG 2.1 Level AA and Section 508
                      accessibility standards.</p>
                  </div>

                  <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                      <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                      <FontAwesomeIcon icon={faClipboardList} className="fa-stack-1x" />
                    </span>
                    <h4 className="my-3">Detailed results</h4>
                    <p className="text-muted">We scan your entire website and return a concise list of issues on each page, where to find them,
                      and how to fix them.</p>
                  </div>

                  <div className="col-md-4">
                    <span className="fa-stack fa-4x">
                      <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
                      <FontAwesomeIcon icon={faSyncAlt} className="fa-stack-1x" />
                    </span>
                    <h4 className="my-3">Instant re-auditing</h4>
                    <p className="text-muted">Follow-up scans can be performed after making changes to your website, helping you track your
                      progress on the road to full compliance.</p>
                  </div>
                </div>
              </section>
              <section id="home-details-1" className="pt-4">
                <div className="row d-flex h-100">
                  <div className="col-lg-6 col-md-4 justify-content-center align-self-center">
                    <img src="/img/screenshot.png" alt="Laptop displaying the Publica11y audit interface." className="img-fluid" />
                  </div>
                  <div className="col-lg-6 col-md-8 justify-content-center align-self-center">
                    <h4 className="my-3">From the accessibility experts at Zodiac Media.</h4>
                    <p className="text-muted">Publica11y is a free service provided by <a href="https://www.zodiacmedia.co.uk/" title="Zodiac Media" target="_blank" rel="noopener noreferrer">Zodiac Media</a>, a London-based website design and development agency specialising in the public sector.</p>
                    <p className="text-muted">Utilising leading open source technologies, Publica11y can scan as many websites as required, providing in-depth reports on areas where work is needed, along with detailed instructions on how to achieve compliance.</p>
                  </div>
                </div>
              </section>

              {/*<section id="home-details-2" className="pt-5">
                <div className="row">
                  <div className="col-md-7">
                    <h4 className="my-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h4>
                    <p className="text-muted">Nunc tempor ullamcorper augue, in auctor ex. Donec cursus leo at libero efficitur, a vehicula erat sollicitudin. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent posuere felis ut mattis lobortis. Ut et ultrices leo, a venenatis nisl. Ut dapibus pellentesque condimentum. Aenean nec diam interdum quam congue consequat a non tellus.</p>
                    <p className="text-muted">Aenean blandit felis iaculis magna tempor, suscipit suscipit metus venenatis. Nam malesuada nisi et magna fermentum semper. Donec urna sapien, viverra et vestibulum quis, tempus vitae purus. Praesent efficitur nisl laoreet justo aliquam fermentum. Nunc tempus, risus at ornare volutpat, sapien risus porta tellus, sed porta ante metus vel ipsum. Nulla quis ex blandit, mollis urna quis, pretium enim.</p>
                  </div>
                  <div className="col-md-5 pt-2">
                    <img src="/img/placeholder2.jpg" alt="TODO" className="img-fluid img-thumbnail" />
                  </div>
                </div>
              </section>*/}

              <section id="home-details" className="py-5 text-center">
                <h3 className="my-3">Get in touch for a free accessibility scan.</h3>
                <p className="text-muted">If you wish to request an audit of your site please send us an email outlining the websites you wish to scan.</p>
                <Button href="mailto:info@zodiacmedia.co.uk?subject=Publica11y.org audit request" variant="primary" size="lg">Contact us</Button>
              </section>
            </>
        }
        <section>
          {this.props.permissions.anyAuditCreateAllowed() &&
          <Form.Row>
            <Col xs="auto" className="mr-auto">
              <LinkContainer to="/audits/create">
                <Button>Start a new audit</Button>
              </LinkContainer>
              {' '}
              <ImportButton server={this.props.server} getAudits={this.getAudits}/>
            </Col>
            {
              this.props.permissions.readAllAudits() &&
              <Col xs="auto">
                <Form.Group controlId="auditfilter">
                  <Form.Label>Audit filter</Form.Label>
                  <Form.Control name="auditfilter" as="select" className="sm-2" value={this.state.auditfilter}
                                onChange={this.handleFilterMode}>
                    <option value="">- Default -</option>
                    <option value="api">API only audits</option>
                    <option value="all">API and standard audits</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            }
          </Form.Row>
          }
        </section>
        {anyPermission && this.props.permissions.loggedIn() && auditsHTML &&
          <section>
            <h2>Saved Audits</h2>
            <Table bordered size="sm" className="data expand">
              <thead>
                <tr>
                  <th>Domain</th>
                  <th>Audit name</th>
                  <th className="text-right">Date</th>
                  <th className="text-right">Checked URLs</th>
                  <th className="text-right">Violations</th>
                  {this.props.permissions.anyAuditCreateAllowed() &&
                    <th className="text-right"></th>}
                </tr>
              </thead>
              <tbody>
                {auditsHTML}
              </tbody>
            </Table>
            <Paginate
              location={this.props.location}
              history={this.props.history}
              permissions={this.props.permissions}
              pagination={this.state.pagination}
              fetchData={this.getAudits} />
          </section>
        }
      </>
    );
  }

}

AuditList.propTypes = {
  permissions: PropTypes.instanceOf(Permissions),
  server: PropTypes.instanceOf(ServerAPI).isRequired,
};

export default AuditList;
