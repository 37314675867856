import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from "react-bootstrap/Button";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';

import { faTrashAlt, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ServerAPI from '../ServerAPI';
import Categories from './Categories';
import DomainTable from './DomainTable';
import PageTable from './PageTable';
import ViolationStats from './ViolationStats';
import Permissions from '../access/Permissions';


class Audit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      audit: null,
      domain: null, // used when there is only 1 domain for the audit
      error: null,
      success: null,
      statusLink: false,
    };
    this.renderAuthenticationInfo = this.renderAuthenticationInfo.bind(this);
    this.exportAudit = this.exportAudit.bind(this);
    this.deleteAudit = this.deleteAudit.bind(this);
  }

  async componentDidMount() {
    try {
      const audit = await this.props.server.getAudit(this.props.match.params.auditId);
      document.title = "Accessibility Audit: " + audit.initialDomainName;
      this.setState({ audit });
      if (audit.domains && audit.domains.length === 1) {
        // load the domain when there is only 1 for the audit
        const domain = await this.props.server.getDomain(audit.domains[0]._id);
        this.setState({ domain });
      }
      if (!audit.complete) {
        try {
          const status = await this.props.server.getAuditStatus(this.props.match.params.auditId);
          if (status.running)
            this.setState({ statusLink: true });
        } catch (error) {
          // ignore this one (the audit is probably not in memory anymore)
        }
      }
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.error && !prevState.error)
      document.querySelector('.alert').focus();
  }

  getCloneUrl() {
    let url = '/audits/create';
    if (this.state.audit) {
      const props = [
        'label', 'firstURL', 'standard', 'sitemaps', 'sitemapName', 'customList', 'urlList', 'checkSubdomains',
        'maxDepth', 'maxPagesPerDomain', 'includeMatch', 'excludeURLs', 'browser', 'postLoadingDelay',
        'authMethod', 'loginURL', 'loginUser', 'loginPass', 'sessionCookies', 'statusCheckMode', 'extraOptions',
      ];
      const queryParams = {};
      for (const prop of props) {
        const propType = typeof this.state.audit[prop];
        if (!['string', 'number', 'boolean'].includes(propType)) {
          // Only interested in valid scalar values.
          // console.log('param does not exist: ', prop);
          continue;
        }
        if ('loginPass' === prop) {
          // Remove sensitive info from the URL. It'll need to be manually repopulated.
          queryParams[prop] = '';
        }
        else {
          queryParams[prop] = this.state.audit[prop];
        }
      }
      const query = new URLSearchParams(queryParams);
      url += '?' + query.toString();
    }
    return url;
  }

  renderAuthenticationInfo() {
    if (!this.state.audit.authMethod) {
      return null;
    }
    return (
      <>
        <tr>
          <th>Authentication method</th>
          <td className="code">{this.state.audit.authMethod}</td>
        </tr>
        {
          this.state.audit.authMethod === 'form' &&
          (
            <>
              <tr>
                <th>Login URL</th>
                <td>{this.state.audit.loginURL}</td>
              </tr>
              <tr>
                <th>Login User</th>
                <td>{this.state.audit.loginUser}</td>
              </tr>
              <tr>
                <th>Login Password</th>
                <td>{this.state.audit.loginPass}</td>
              </tr>
            </>
          )
        }
        {
          this.state.audit.authMethod === 'cookies' &&
          (
            <>
              <tr>
                <th>Session cookies</th>
                <td className="code">{
                  (this.state.audit.sessionCookies && <code><b>{this.state.audit.sessionCookies}</b></code>)
                }</td>
              </tr>
            </>
          )
        }
      </>
    );
  }

  deleteAudit() {
    if (this.state.audit && this.state.audit._id) {
      const auditId = this.state.audit._id;
      const result = window.confirm("Delete the audit?");
      if (result) {
        this.props.server.removeAudit(auditId)
          .then(() => {
            this.setState({ success: "The audit was successfully deleted." });
          })
          .catch((error) => {
            this.setState({ error: "Delete audit: " + error });
          });
      }
    }
  }

  exportAudit() {
    if (this.state.audit && this.state.audit._id) {
      this.props.server.exportAudit(this.state.audit._id)
    }
  }

  render() {
    const standardTitles = {
      wcag2a: "WCAG 2.0 Level A",
      wcag2aa: "WCAG 2.0 Level AA",
      wcag21aa: "WCAG 2.1 Level AA",
      section508: "Section 508",
    };
    const cloneUrl = this.getCloneUrl();

    return (
      <>
        <Breadcrumb>
          <LinkContainer to="/audits/">
            <Breadcrumb.Item>Audits</Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>Audit</Breadcrumb.Item>
        </Breadcrumb>
        <Alert show={this.state.error != null} variant="danger" dismissible
            onClose={() => this.setState({ error: null })} tabIndex="0">
          {this.state.error}
        </Alert>
        <Alert show={this.state.success != null} variant="success" dismissible
               onClose={() => this.setState({ success: null })} tabIndex="0">
          {this.state.success}
        </Alert>
        <h1 className="text-center">{this.state.audit ?
          <span className="code">{this.state.audit.initialDomainName}</span>
          : ''}</h1>
        {this.state.audit &&
          <>
            {this.state.statusLink &&
              <p>The audit is still running. <Link to={'/audits/' + this.props.match.params.auditId + '/status'}>See status page</Link>.</p>
            }
            {
              this.props.permissions.anyAuditCreateAllowed() &&
              <>
                <Button href={cloneUrl} target="_blank" rel="noopener noreferrer">Clone audit</Button>{' '}
                <Button title="Export audit" variant="info" onClick={this.exportAudit}><FontAwesomeIcon icon={faDownload} title="Export audit" /> Export</Button>{' '}
              </>
            }
            {
              this.props.permissions.domainDeleteAllowed(this.state.audit.initialDomainName) &&
              <Button title="Delete audit" variant="danger" onClick={this.deleteAudit}><FontAwesomeIcon icon={faTrashAlt} title="Delete audit" /> Delete</Button>
            }
            <section>
              <h2>Audit Parameters</h2>
              <Table bordered size="sm" className="data">
                <tbody>
                  <tr>
                    <th>Audit name</th>
                    <td className="code">{this.state.audit.label}</td>
                  </tr>
                  <tr>
                    <th>First URL</th>
                    <td className="code">{this.state.audit.firstURL}</td>
                  </tr>
                  <tr>
                    <th>Standard</th>
                    <td>{standardTitles[this.state.audit.standard]}</td>
                  </tr>
                  <tr>
                    <th>Use site maps</th>
                    <td className="code">{this.state.audit.sitemaps ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th>Sitemap name</th>
                    <td className="code">{this.state.audit.sitemapName || "sitemap.xml"}</td>
                  </tr>
                  <tr>
                    <th>Use custom URL list</th>
                    <td className="code">{this.state.audit.customList ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th>Custom URL list</th>
                    <td className="code">{this.state.audit.urlList}</td>
                  </tr>
                  <tr>
                    <th>Check subdomains</th>
                    <td className="code">{this.state.audit.checkSubdomains ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th>Maximum depth</th>
                    <td>{this.state.audit.maxDepth}</td>
                  </tr>
                  <tr>
                    <th>Maximum number of pages checked per domain</th>
                    <td>{this.state.audit.maxPagesPerDomain}</td>
                  </tr>
                  <tr>
                    <th>Include only paths matching the regular expression</th>
                    <td className="code">{
                      (this.state.audit.includeMatch && <code>/<b>{this.state.audit.includeMatch}</b>/</code>)
                    }</td>
                  </tr>
                  <tr>
                    <th>Exclude URL paths</th>
                    <td className="code">{
                      (this.state.audit.excludeURLs && <code><b>{this.state.audit.excludeURLs}</b></code>)
                    }</td>
                  </tr>
                  {this.renderAuthenticationInfo()}
                  <tr>
                    <th>Web browser</th>
                    <td>{this.state.audit.browser}</td>
                  </tr>
                  <tr>
                    <th>Additional delay to let dynamic pages load (ms)</th>
                    <td>{this.state.audit.postLoadingDelay}</td>
                  </tr>
                  {/* https://stackoverflow.com/a/53519842 */}
                  {
                    this.state.audit.statusCheckMode ?
                    <tr>
                      <th>[ADVANCED] Status check method</th>
                      <td className="code">{this.state.audit.statusCheckMode}</td>
                    </tr> : null
                  }
                  {
                    this.state.audit.extraOptions ?
                    <tr>
                      <th>[ADVANCED] Configuration options</th>
                      <td className="code"><code><b>{this.state.audit.extraOptions}</b></code></td>
                    </tr> : null
                  }
                  <tr>
                    <th>Date started</th>
                    <td>{(new Date(this.state.audit.dateStarted)).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Date ended</th>
                    <td>{this.state.audit.dateEnded &&
                      (new Date(this.state.audit.dateEnded)).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <th>Audit completed</th>
                    <td>{this.state.audit.complete ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <th>Number of checked URLs</th>
                    <td>{this.state.audit.nbCheckedURLs}</td>
                  </tr>
                  <tr>
                    <th>Number of accessibility violations</th>
                    <td>{this.state.audit.nbViolations}</td>
                  </tr>
                  <tr>
                    <th>Number of scan errors</th>
                    <td>{this.state.audit.nbScanErrors}</td>
                  </tr>
                </tbody>
              </Table>
            </section>
            { this.state.audit.categories &&
              <Categories categories={this.state.audit.categories}/>
            }
            {this.state.domain ?
              <>
                <ViolationStats stats={this.state.domain.violationStats}
                  items={this.state.domain.pages} itemType="page"/>
                <PageTable domain={this.state.domain}/>
              </>
              :
              <>
                <ViolationStats stats={this.state.audit.violationStats}
                  items={this.state.audit.domains} itemType="domain"/>
                <DomainTable audit={this.state.audit}/>
              </>
            }
          </>
        }
      </>
    );
  }

}

Audit.propTypes = {
  server: PropTypes.instanceOf(ServerAPI).isRequired,
  permissions: PropTypes.instanceOf(Permissions),
  match: PropTypes.shape({
    params: PropTypes.shape({
      auditId: PropTypes.string.isRequired,
    })
  }),
};

export default Audit;
