import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const MatomoTracking = () => {
  const { trackPageView, trackEvent, trackLink } = useMatomo();

  const location = useLocation();

  // Track page view.
  useEffect(() => {
    trackPageView({});
  },
  [location, trackPageView]
  );

  useEffect(() => {
    // Track links ourselves rather than via useMatomo.enableLinkTracking() since it doesn't currently pick up child
    // elements.
    // https://github.com/Amsterdam/matomo-tracker/blob/5652f05/packages/react/src/utils/useOutboundClickListener.tsx
    const handleOutboundClick = (event) => {
      // The target is not guaranteed to be a link, it could be a child element.
      // Look up the element parent until the anchor element is found.
      const findLinkElement = (el) => {
        if (el instanceof HTMLAnchorElement && el.href) {
          return el;
        } else if (el.parentElement) {
          return findLinkElement(el.parentElement);
        }
        return null;
      };

      const target = findLinkElement(event.target);

      if (!target) {
        return;
      }

      const { href } = target;

      // Check if the click target differs from the current hostname, meaning it's external
      if (
        !href.match(
          new RegExp(
            `^(https?://)(www.)?(${window.location.hostname})`,
          ),
        )
      ) {
        if (href.match(/^mailto:/)) {
          // Track an email event.
          trackEvent({
            category: 'Mails',
            action: 'Click',
            name: href.substring(7),
          });
        } else {
          trackLink({ href });
        }
      }
    };

    window.document.addEventListener('click', handleOutboundClick, {
      capture: true,
    });

    return () =>
      window.document.removeEventListener('click', handleOutboundClick, {
        capture: true,
      });
  }, [trackEvent, trackLink]);

  return null;
};

export default MatomoTracking;
