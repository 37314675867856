
class ServerAPI {

  constructor() {
    this.cache = {
      auditList: null,
      usersList: null,
      lastAudit: null,
      lastDomain: null,
      lastPage: null,
    };
    this.hostURL = window.location.origin;
  }

  request(method, path, parameters) {
    return new Promise((resolve, reject) => {
      const controller = new AbortController();
      const fetchParams = {
        method: method,
        signal: controller.signal
      };
      if (parameters != null) {
        fetchParams.headers = { 'Content-Type': 'application/json' };
        fetchParams.body = (typeof parameters === 'string') ? parameters :
          JSON.stringify(parameters);
      }
      let request = fetch(path, fetchParams)
          .then((response) => {
            if (!response.ok)
              reject(new Error(response.statusText));
            return response;
          })
          .then(data => data.json())
          .then((res) => {
            if (!res.success)
              reject(new Error(res.error));
            else
              resolve(res.data);
          })
          .catch(error => {
            if (error.name === 'AbortError')
              reject(new Error("Timeout when connecting to server (" +
                  error.message + ")"));
            else
              reject(new Error("Error retrieving data: " + error.message));
          });

      if (process.env.REACT_APP_NODE_ENV === 'production') {
        // Apply request timeouts to production environments only.
        const timeout = setTimeout(() => controller.abort(), parseInt(process.env.REACT_APP_REQUEST_TIMEOUT) || 3000);
        request
            .finally(() => clearTimeout(timeout));
      }
    });
  }

  // App
  async localLogin(username, password) {
    const user = await this.request('POST', '/api/app/login', { username, password });
    this.cache = {};
    return user;
  }
  samlLogin() {
    window.location.href = this.hostURL + '/api/app/login/saml';
  }
  async logout() {
    await this.request('POST', '/api/app/logout');
    this.cache = {};
  }

  // Audit
  getAuditStatus(auditId) {
    return this.request('GET', `/api/audits/${auditId}/status`);
  }
  startAudit(params) {
    this._clearCache('auditList');
    return this.request('POST', '/api/audits/start', params);
  }
  stopAudit(auditId) {
    return this.request('POST', `/api/audits/${auditId}/stop`);
  }

  getAudits(page = 1, mode = null) {
    const query = mode ? `?mode=${mode}` : '';
    const extra = mode ? `mode=${mode}` : null;
    return this._getPaginatedResults(page, `/api/audits/${query}`, 'auditList', extra);
  }

  async getAudit(auditId) {
    if (this.cache.lastAudit != null && this.cache.lastAudit._id === auditId)
      return this.cache.lastAudit;
    const audit = await this.request('GET', `/api/audits/${auditId}`);
    this.cache.lastAudit = audit;
    return audit;
  }
  removeAudit(auditId) {
    this._clearCache('auditList');
    return this.request('DELETE', `/api/audits/${auditId}`);
  }
  exportAudit(auditId) {
    window.location.href = this.hostURL + `/api/audits/${auditId}/export`;
  }
  async importAudit(data) {
    this._clearCache('auditList');
    await this.request('POST', `/api/audits/import`, data);
  }
  async getDomain(domainId) {
    if (this.cache.lastDomain != null && this.cache.lastDomain._id === domainId)
      return this.cache.lastDomain;
    const domain = await this.request('GET', `/api/domains/${domainId}`);
    this.cache.lastDomain = domain;
    return domain;
  }
  /*getDomainPages(domainId) {
    return this.request('GET', `/api/domains/${domainId}/pages`);
  }*/
  async getPage(pageId) {
    if (this.cache.lastPage != null && this.cache.lastPage._id === pageId)
      return this.cache.lastPage;
    const page = await this.request('GET', `/api/pages/${pageId}`);
    this.cache.lastPage = page;
    return page;
  }

  getUsers(page = 1) {
    return this._getPaginatedResults(page, '/api/users/', 'usersList');
  }
  generateApiToken() {
    return this.request('GET', '/api/users/generate-token/');
  }
  createUser(user) {
    this._clearCache('usersList');
    return this.request('POST', `/api/users/`, user);
  }
  getUser(userId) {
    return this.request('GET', `/api/users/${userId}`);
  }
  removeUser(userId) {
    this._clearCache('usersList');
    return this.request('DELETE', `/api/users/${userId}`);
  }
  updateUser(user) {
    this._clearCache('usersList');
    return this.request('PUT', `/api/users/${user._id}`, user);
  }
  addUserGroup(userId, groupId) {
    return this.request('PUT', `/api/users/${userId}/groups/${groupId}`);
  }
  removeUserGroup(userId, groupId) {
    return this.request('DELETE', `/api/users/${userId}/groups/${groupId}`);
  }
  getCurrentUser() {
    return this.request('GET', '/api/users/current');
  }

  getGroups() {
    return this.request('GET', '/api/groups/');
  }
  createGroup(group) {
    return this.request('POST', `/api/groups/`, group);
  }
  getGroup(groupId) {
    return this.request('GET', `/api/groups/${groupId}`);
  }
  removeGroup(groupId) {
    return this.request('DELETE', `/api/groups/${groupId}`);
  }
  updateGroup(group) {
    return this.request('PUT', `/api/groups/${group._id}`, group);
  }
  addGroupUser(groupId, userId) {
    return this.request('PUT', `/api/groups/${groupId}/users/${userId}`);
  }
  removeGroupUser(groupId, userId) {
    return this.request('DELETE', `/api/groups/${groupId}/users/${userId}`);
  }

  async _getPaginatedResults(page, apiPath, cacheKey, filter) {
    filter = filter || 'default';
    if (this.cache[cacheKey] && this.cache[cacheKey][filter] && this.cache[cacheKey][filter][page]) {
      return this.cache[cacheKey][filter][page];
    }
    const join = apiPath.includes('?') ? '&' : '?';
    const results = await this.request('GET', `${apiPath}${join}page=${page}`);
    if (!this.cache[cacheKey]) {
      this.cache[cacheKey] = {};
    }
    if (!this.cache[cacheKey][filter]) {
      this.cache[cacheKey][filter] = {};
    }
    this.cache[cacheKey][filter][page] = results;

    return results;
  }
  
  _clearCache(cacheKey) {
    this.cache[cacheKey] = null;
  }
}

export default ServerAPI;
